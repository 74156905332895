.nav.nav-tabs .nav-item .nav-link {
  padding: 0.8rem 2rem;
  border-radius: 5px;
  background-color: #fff;
  color: var(--bs-secondary);
  border: none;
  box-shadow: 0px 0px 14px 0px rgba(183, 192, 206, 0.18);
  font-weight: 600 !important;
}

.nav.nav-tabs .nav-item .nav-link.active {
  // border-color:
  background-color: var(--bs-primary);
  color: #fff;
}

.table_tab .nav-tabs.nav {
  gap: 0.6rem;
  border: none;
}

.tab-content {
  padding: 0 !important;
  border: none !important;
  margin-top: 1rem;
}

.quotation_head {
  font-weight: 600;
}

.table_tab ul {
  justify-content: center;
}

.card_rows {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

// .create_quotation_table span {
//   color: var(--bs-danger);
// }

.create_quotation_table label {
  font-weight: 600;
}

input:disabled,
.form-select:disabled,
.dataTable-top .dataTable-dropdown label select:disabled {
  background-color: #f3f7ff;
}

.card_head {
  font-weight: 700;
  font-size: 1.2rem;
  text-transform: capitalize;
}

.create_quotation_table {
  margin-top: 2.5rem;
}

.step_number {
  background-color: #f1f4f6;
  color: var(--bs-secondary);
  font-size: 1rem;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.step_tab {
  display: flex;
  align-items: center;
  text-align: center;
  gap: 0.5rem;
}

.nav.nav-tabs .nav-item .nav-link.active .step_number {
  color: var(--bs-primary);
  background-color: #fff;
}

#ck-button {
  margin: 4px;
  background-color: #dfe2ffc5;
  border-radius: 10px;
  //  border:1px solid #D0D0D0;
  overflow: auto;
  float: left;
}

#ck-button label {
  float: left;
  width: 8rem;
  cursor: pointer;
}

#ck-button label span {
  text-align: center;
  padding: 10px 10px;
  display: block;
}

#ck-button label input {
  display: none;
}

#ck-button input:checked + span {
  background-color: var(--bs-primary);
  color: #fff;
  font-weight: 600;
}

.area_section {
  background-color: #f1f4f6;
  padding: 2rem 0;
  margin-top: 2rem;
}

.sub_heading {
  // font-size: 1.2rem;
  font-weight: 500;
}

.radio-toolbar input[type="radio"]:checked + label {
  background-color: var(--bs-primary);
  color: #fff;
  font-weight: 600;
}

.radio-toolbar input[type="radio"] {
  display: none;
}

.radio-toolbar label {
  float: left;
  width: 8rem;
  cursor: pointer;
}
.radio-toolbar {
  margin: 4px;
  background-color: #dfe2ffc5;
  border-radius: 10px;
  overflow: auto;
  float: left;
}
.radio-toolbar label span {
  text-align: center;
  padding: 10px 10px;
  display: block;
}
.toggle_btn {
  padding: 2rem 1rem;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

//
.switch {
  position: relative;
  display: block;
  vertical-align: top;
  width: 65px;
  height: 23px;
  padding: 3px;
  margin: 0 10px 10px 0;
  // background: linear-gradient(to bottom, #eeeeee, #FFFFFF 25px);
  // background-image: -webkit-linear-gradient(top, #eeeeee, #FFFFFF 25px);
  border-radius: 18px;
  // box-shadow: inset 0 -1px white, inset 0 1px 1px rgba(0, 0, 0, 0.05);
  cursor: pointer;
}
.switch-input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}
.switch-label {
  position: relative;
  display: block;
  height: inherit;
  font-size: 10px;
  text-transform: uppercase;
  background: #eceeef;
  border-radius: inherit;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.12),
    inset 0 0 2px rgba(0, 0, 0, 0.15);
}
.switch-label:before,
.switch-label:after {
  position: absolute;
  top: 50%;
  margin-top: -0.45em;
  line-height: 1;
  -webkit-transition: inherit;
  -moz-transition: inherit;
  -o-transition: inherit;
  transition: inherit;
}
.switch-label:before {
  content: attr(data-off);
  right: 11px;
  color: #aaaaaa;
  text-shadow: 0 1px rgba(255, 255, 255, 0.5);
}
.switch-label:after {
  content: attr(data-on);
  left: 11px;
  color: #ffffff;
  text-shadow: 0 1px rgba(0, 0, 0, 0.2);
  opacity: 0;
}
.switch-input:checked ~ .switch-label {
  background: #30c620;
  border-color: #30c620;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.15),
    inset 0 0 3px rgba(0, 0, 0, 0.2);
}
.switch-input:checked ~ .switch-label:before {
  opacity: 0;
}
.switch-input:checked ~ .switch-label:after {
  opacity: 1;
}
.switch-handle {
  position: absolute;
  top: 4px;
  left: 4px;
  width: 21px;
  height: 21px;
  background: linear-gradient(to bottom, #ffffff 40%, #f0f0f0);
  background-image: -webkit-linear-gradient(top, #ffffff 40%, #f0f0f0);
  border-radius: 100%;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
}
.switch-handle:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -6px 0 0 -6px;
  width: 12px;
  height: 12px;
  background: linear-gradient(to bottom, #eeeeee, #ffffff);
  background-image: -webkit-linear-gradient(top, #eeeeee, #ffffff);
  border-radius: 6px;
  box-shadow: inset 0 1px rgba(0, 0, 0, 0.02);
}
.switch-input:checked ~ .switch-handle {
  left: 40px;
  box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.2);
}

/* Transition
  ========================== */
.switch-label,
.switch-handle {
  transition: All 0.3s ease;
  -webkit-transition: All 0.3s ease;
  -moz-transition: All 0.3s ease;
  -o-transition: All 0.3s ease;
}
.toggle_block {
  display: flex;
  align-items: center;
}
.toggle_label {
  font-weight: 500;
}

.plan_dropdown {
  width: 100%;
}
.create_quote_main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1.5rem;
  // padding: 1.5rem 1rem 2rem;
}
.delete_quote i {
  color: var(--bs-danger);
  font-size: 1rem;
  border: 1px solid var(--bs-danger);
  padding: 0.5rem;
  border-radius: 50%;
}
.card.bg_gray {
  background-color: #edf2f7 !important;
}
.card_table .stretch-card {
  align-items: center;
}

.stretch-card > .card {
  min-width: 98% !important;
}
.add_plan_btn {
  margin: 1.5rem 0 0 1.6rem;
}

.select_insurer {
  padding: 1rem !important;
}
.review_table tr th {
  background-color: #edf2f7;
  color: #0c1427;
  padding: 1.5rem;
}
.review_table_head tr th {
  background-color: #0c1427;
  font-weight: 600;
  color: #fff;
  text-align: center;
}
.review_table tr td {
  text-align: left;
  min-width: 280px;
  max-width: 280px;
  // text-wrap: wrap !important;
  white-space: wrap !important;
}
.review_table tr td i {
  color: #30c620;
  font-weight: 600;
  font-size: 1.1rem;
}
.download_file {
  display: flex;
  gap: 2rem;
}
.download_file a {
  color: #000;
}
.share_number h3 {
  background-color: #000;
  padding: 1rem;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  color: #fff;
}

.review_table tr > th:first-child,
tr > td:first-child {
  position: sticky;
  left: -1px;
  z-index: 1;
}
.plain_row {
  background-color: #edf2f7;
}
.table_input_field {
  border: none !important;
  background-color: #eeeeee83 !important;
  padding: 0.5rem 1.3rem;
}
.table_input_field:focus {
  // border: none;
  box-shadow: none !important;
  border: none !important;
}
.modal-header {
  padding-bottom: 0 !important;
}

.tab-content {
  min-height: 70vh;
}

.ngx-datatable.bootstrap
  .datatable-body
  .datatable-body-row
  .datatable-body-cell {
  padding: 0.75rem;
  text-align: left;
  vertical-align: top;
}

.ng-option-label {
  color: #000 !important;
}
.ng-select div,
.ng-select input,
.ng-select span {
  color: #000 !important;
}
.ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-value {
  background-color: rgba(223, 226, 255, 0.7725490196);
}

::ng-deep {
  .iti--allow-dropdown {
    width: 100%;
  }
}
.separate-dial-code {
  width: 100%;
}

.review_table_head {
  tr th {
    vertical-align: middle;
    white-space: break-spaces;
  }
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  white-space: break-spaces !important;
}
