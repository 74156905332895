// Write your custom css/scss here

.primary-btn {
  background-color: var(--bs-primary);
  color: #fff;
  border: none;

  &:hover {
    background-color: var(--bs-primary);
    color: #fff;
  }

  &:focus {
    background-color: var(--bs-primary);
  }
}

.primary-btn:focus:not(:focus-visible) {
  background-color: var(--bs-primary) !important;
  color: #fff !important;
  border: none !important;
}

.sidebar-nav.sidebar_button {
  padding: 25px 25px 10px 25px !important;
  margin: 0;
}

.quotation_head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sidebar .sidebar-body .sidebar-nav .nav-item.mm-active > .nav-link .link-icon {
  fill: none;
}

.sidebar .sidebar-body .sidebar-nav .nav-item > .nav-link:hover {
  fill: none;
  color: var(--bs-primary) !important;
}

.sidebar .sidebar-body .sidebar-nav .nav-item > .nav-link .link-icon {
  fill: none !important;

  &:hover {
    fill: none;
  }
}

.nav-link.nav-link-ref.mm-active .link-title {
  color: var(--bs-primary);
}

.sidebar .sidebar-body .sidebar-nav .nav-item > .nav-link .link-title:hover {
  fill: none;
  color: var(--bs-primary);
}

.nav-link.nav-link-ref:hover {
  fill: none !important;
}

// data table
.ngx-datatable.bootstrap
  .datatable-body
  .datatable-body-row.datatable-row-even {
  background-color: #f6f8fb;
}

.ngx-datatable.bootstrap .datatable-body .datatable-body-row {
  border-color: transparent;
}

.ngx-datatable.bootstrap .datatable-header .datatable-header-cell {
  border-color: transparent;
}

.ngx-datatable.bootstrap
  .datatable-header
  .datatable-header-cell
  .datatable-header-cell-label {
  font-weight: 600;
  color: #5f6072;
  text-transform: uppercase;
}

.ngx-datatable.bootstrap .datatable-footer .page-count {
  display: none;
}

.ngx-datatable.bootstrap .datatable-footer {
  background-color: transparent;
}

.ngx-datatable.bootstrap
  .datatable-footer
  .datatable-pager
  ul
  li:not(.disabled).active
  a {
  border-radius: 50%;
  font-weight: 400;
}
.ngx-datatable.bootstrap
  .datatable-footer
  .datatable-pager
  ul
  li:not(.disabled).active
  a,
.ngx-datatable.bootstrap
  .datatable-footer
  .datatable-pager
  ul
  li:not(.disabled):hover
  a {
  border-radius: 50% !important;
}
// .ngx-datatable.bootstrap .datatable-footer .datatable-pager ul li:not(.disabled) a{
//     display: flex !important;
//     align-items: center !important;
//     justify-content: center !important;
// }
.ngx-datatable.bootstrap .datatable-footer .datatable-pager a {
  height: 26px;
  min-width: 27px;
  line-height: 25px;

  &:hover {
    border-radius: 50%;
  }
}
.ngx-datatable.bootstrap
  .datatable-body
  .datatable-body-row
  .datatable-body-cell {
  font-weight: 600;
  display: flex !important;
  align-items: center !important;
}

.buy-now-wrapper {
  position: fixed;
  bottom: 30px;
  right: 35px;
  z-index: 999;
}

.buy-now-wrapper button {
  border-radius: 50%;
  width: 50px !important;
  height: 50px !important;
  background: linear-gradient(208deg, #babfff 6.36%, #6571ff 81.94%) !important;
  border: none;
}
.buy-now-wrapper button i {
  font-size: 1.4rem !important;
}
.user_media.pointer {
  cursor: pointer;
}

.user_media {
  gap: 0.5rem;

  .user_media {
    flex: 1;
  }
}

.user_icon {
  min-width: 38px;
  height: 38px;
  background-color: #43434f;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: 500;
}
.color_1 {
  background-color: #ef98b2 !important;
}

.color_2 {
  background-color: #80dcbd !important;
}

.color_3 {
  background-color: #e2a2e0 !important;
}

.color_4 {
  background-color: #f0af9c !important;
}

.color_5 {
  background-color: #8ec7e4 !important;
}

.color_6 {
  background-color: #b897fa !important;
}

.modal-header,
.modal-footer {
  border: none !important;
}
.sidebar_button li button {
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}
.sidebar_button li button span {
  transition: all 0.2s ease-in-out;
}

.sidebar-folded .sidebar_button li button {
  width: 40px;
  display: block;
}
.sidebar-folded .sidebar_button li button span {
  visibility: hidden;
  opacity: 0;
  transition: all 0.1s ease-in-out;
}

.open-sidebar-folded .sidebar_button li button span {
  visibility: visible;
  opacity: 1;
}
.open-sidebar-folded .sidebar_button li button {
  width: 100%;
  display: flex;
}

.sidebar-folded #sidebar-create-btn {
  padding-left: 14px !important;
}

.create_quote_main .plan_dropdown p {
  white-space: nowrap;
}
.form-label span {
  color: #f00;
}
.flex-1 {
  flex: 1;
}
// loader
.bar {
  width: 4em;
  height: 4em;
  border: 0.5em solid rgba(0, 0, 0, 0.1);
  border-left-color: var(--bs-primary);
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin: auto;
}
.progress-linear {
  width: 100%;
  min-height: 100%;
  position: absolute;
  z-index: 9999;
  background-color: rgba(255, 255, 255, 0.7);
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
}
.ngx-datatable .datatable-body {
  min-height: 50vh;
}
@media (max-width: 991px) {
  .create_quote_main {
    flex-wrap: wrap;
  }
}

@media (max-width: 767px) {
  .nav.nav-tabs .nav-item .nav-link {
    padding: 0.5rem 1rem !important;
  }
  .toggle_btn {
    justify-content: space-between !important;
  }
  .create_quote_main {
    gap: 0.5rem !important;
  }
  .download_file {
    flex-wrap: wrap;
  }
  .review_table tr > th:first-child,
  tr > td:first-child {
    position: static !important;
    // left: -1px;
  }
}

.iti {
  width: 100%;
}
